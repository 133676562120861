import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.promise.js";import"core-js/modules/es.array.iterator.js";import"core-js/modules/es.string.iterator.js";import"core-js/modules/web.dom-collections.iterator.js";import"core-js/modules/web.url.js";import"core-js/modules/web.url-search-params.js";import"core-js/modules/es.array.concat.js";import"core-js/modules/es.array.includes.js";import"core-js/modules/es.regexp.exec.js";import"core-js/modules/es.string.match.js";/* eslint-disable no-case-declarations */var DEFAULT_MAX_PIXELS=1920*1080;var _browser_quirks={};// Some browsers will automatically draw images respecting their EXIF orientation
// while others won't, and the safest way to detect that is to examine how it
// is done on a known image.
// See https://github.com/w3c/csswg-drafts/issues/4666
// and https://github.com/blueimp/JavaScript-Load-Image/commit/1e4df707821a0afcc11ea0720ee403b8759f3881
var dropOrientationIfNeeded=function dropOrientationIfNeeded(orientation){return new Promise(function(resolve){switch(_browser_quirks['image-orientation-automatic']){case true:resolve(1);break;case false:resolve(orientation);break;default:// black 2x1 JPEG, with the following meta information set:
// - EXIF Orientation: 6 (Rotated 90° CCW)
var testImageURL='data:image/jpeg;base64,/9j/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAYAAAA'+'AAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBA'+'QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQE'+'BAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAEAAgMBEQACEQEDEQH/x'+'ABKAAEAAAAAAAAAAAAAAAAAAAALEAEAAAAAAAAAAAAAAAAAAAAAAQEAAAAAAAAAAAAAAAA'+'AAAAAEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwA/8H//2Q==';var img=new Image();img.onload=function(){var automatic=img.width===1&&img.height===2;_browser_quirks['image-orientation-automatic']=automatic;resolve(automatic?1:orientation);};img.onerror=function(){_browser_quirks['image-orientation-automatic']=false;resolve(orientation);};img.src=testImageURL;}});};// /**
//  *Some browsers don't allow reading from a canvas and instead return all-white
//  * or randomized data. Use a pre-defined image to check if reading the canvas
//  * works.
//  */
// const checkCanvasReliability = () => new Promise<void>((resolve, reject) => {
//   switch(_browser_quirks['canvas-read-unreliable']) {
//   case true:
//     reject('Canvas reading unreliable');
//     break;
//   case false:
//     resolve();
//     break;
//   default:
//     // 2×2 GIF with white, red, green and blue pixels
//     const testImageURL =
//       'data:image/gif;base64,R0lGODdhAgACAKEDAAAA//8AAAD/AP///ywAAAAAAgACAAACA1wEBQA7';
//     const refData =
//       [255, 255, 255, 255,  255, 0, 0, 255,  0, 255, 0, 255,  0, 0, 255, 255];
//     const img = new Image();
//     img.onload = () => {
//       const canvas  = document.createElement('canvas');
//       const context = canvas.getContext('2d');
//       context?.drawImage(img, 0, 0, 2, 2);
//       const imageData = context?.getImageData(0, 0, 2, 2);
//       if (imageData?.data.every((x, i) => refData[i] === x)) {
//         _browser_quirks['canvas-read-unreliable'] = false;
//         resolve();
//       } else {
//         _browser_quirks['canvas-read-unreliable'] = true;
//         reject('Canvas reading unreliable');
//       }
//     };
//     img.onerror = () => {
//       _browser_quirks['canvas-read-unreliable'] = true;
//       reject('Failed to load test image');
//     };
//     img.src = testImageURL;
//   }
// });
/** Convert the file into a local blob URL. */var getImageUrl=function getImageUrl(inputFile){return new Promise(function(resolve,reject){var _window$URL;// @ts-ignore: This is a browser capabilities check.
if((_window$URL=window.URL)!==null&&_window$URL!==void 0&&_window$URL.createObjectURL){try{resolve(URL.createObjectURL(inputFile));}catch(error){reject(error);}return;}var reader=new FileReader();reader.onerror=function(){return reject.apply(void 0,arguments);};reader.onload=function(_ref){var target=_ref.target;return resolve((target===null||target===void 0?void 0:target.result)||'');};reader.readAsDataURL(inputFile);});};/** Get an image element from a file. */var loadImage=function loadImage(inputFile){return new Promise(function(resolve,reject){getImageUrl(inputFile).then(function(url){var img=new Image();img.onerror=function(){for(var _len=arguments.length,args=new Array(_len),_key=0;_key<_len;_key++){args[_key]=arguments[_key];}return reject([].concat(args));};img.onload=function(){return resolve(img);};img.src=url;}).catch(reject);});};/** Get the exif orientation for the image. */var getOrientation=function getOrientation(img){var type=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'image/png';return new Promise(function(resolve){if(!['image/jpeg','image/webp'].includes(type)){resolve(1);return;}import(/* webpackChunkName: "features/compose" */'exif-js').then(function(_ref2){var EXIF=_ref2.default;// @ts-ignore: The TypeScript definition is wrong.
EXIF.getData(img,function(){var orientation=EXIF.getTag(img,'Orientation');if(orientation!==1){dropOrientationIfNeeded(orientation).then(resolve).catch(function(){return resolve(orientation);});}else{resolve(orientation);}});}).catch(function(){});});};var processImage=function processImage(img,_ref3){var width=_ref3.width,height=_ref3.height,orientation=_ref3.orientation,_ref3$type=_ref3.type,type=_ref3$type===void 0?'image/png':_ref3$type,_ref3$name=_ref3.name,name=_ref3$name===void 0?'resized.png':_ref3$name;return new Promise(function(resolve,reject){var canvas=document.createElement('canvas');if(4<orientation&&orientation<9){canvas.width=height;canvas.height=width;}else{canvas.width=width;canvas.height=height;}var context=canvas.getContext('2d');if(!context){reject(context);return;}switch(orientation){case 2:context.transform(-1,0,0,1,width,0);break;case 3:context.transform(-1,0,0,-1,width,height);break;case 4:context.transform(1,0,0,-1,0,height);break;case 5:context.transform(0,1,1,0,0,0);break;case 6:context.transform(0,1,-1,0,height,0);break;case 7:context.transform(0,-1,-1,0,height,width);break;case 8:context.transform(0,-1,1,0,0,width);break;}context.drawImage(img,0,0,width,height);canvas.toBlob(function(blob){if(!blob){reject(blob);return;}resolve(new File([blob],name,{type:type,lastModified:new Date().getTime()}));},type);});};var resizeImage=function resizeImage(img,inputFile,maxPixels){return new Promise(function(resolve,reject){var width=img.width,height=img.height;var type=inputFile.type||'image/png';var newWidth=Math.round(Math.sqrt(maxPixels*(width/height)));var newHeight=Math.round(Math.sqrt(maxPixels*(height/width)));// Skip canvas reliability check for now (it's unreliable)
// checkCanvasReliability()
//   .then(getOrientation(img, type))
getOrientation(img,type).then(function(orientation){return processImage(img,{width:newWidth,height:newHeight,name:inputFile.name,orientation:orientation,type:type});}).then(resolve).catch(reject);});};/** Resize an image to the maximum number of pixels. */export default(function(inputFile){var maxPixels=arguments.length>1&&arguments[1]!==undefined?arguments[1]:DEFAULT_MAX_PIXELS;return new Promise(function(resolve){if(!inputFile.type.match(/image.*/)||inputFile.type==='image/gif'){resolve(inputFile);return;}loadImage(inputFile).then(function(img){if(img.width*img.height<maxPixels){resolve(inputFile);return;}resizeImage(img,inputFile,maxPixels).then(resolve).catch(function(error){console.error(error);resolve(inputFile);});}).catch(function(){return resolve(inputFile);});});});