import _slicedToArray from"@babel/runtime/helpers/esm/slicedToArray";import"core-js/modules/es.array.iterator.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.promise.js";import"core-js/modules/es.string.iterator.js";import"core-js/modules/web.dom-collections.iterator.js";import*as BuildConfig from'soapbox/build-config';export var start=function start(){Promise.all([import(/* webpackChunkName: "error" */'@sentry/react'),import(/* webpackChunkName: "error" */'@sentry/tracing')]).then(function(_ref){var _ref2=_slicedToArray(_ref,2),Sentry=_ref2[0],Integrations=_ref2[1].Integrations;Sentry.init({dsn:BuildConfig.SENTRY_DSN,environment:BuildConfig.NODE_ENV,debug:false,integrations:[new Integrations.BrowserTracing()],// Filter events.
// https://docs.sentry.io/platforms/javascript/configuration/filtering/
ignoreErrors:[// Network errors.
'AxiosError',// sw.js couldn't be downloaded.
'Failed to update a ServiceWorker for scope',// Useful for try/catch, useless as a Sentry error.
'AbortError',// localForage error in FireFox private browsing mode (which doesn't support IndexedDB).
// We only use IndexedDB as a cache, so we can safely ignore the error.
'No available storage method found'],denyUrls:[// Browser extensions.
/extensions\//i,/^chrome:\/\//i,/^moz-extension:\/\//i],// We recommend adjusting this value in production, or using tracesSampler
// for finer control
tracesSampleRate:1.0});}).catch(console.error);};export var captureException=function captureException(exception,captureContext){import(/* webpackChunkName: "error" */'@sentry/react').then(function(Sentry){Sentry.captureException(exception,captureContext);}).catch(console.error);};