/**
 * API: HTTP client and utilities.
 * @see {@link https://github.com/axios/axios}
 * @module soapbox/api
 */'use strict';import"core-js/modules/es.array.find.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.object.assign.js";import axios from'axios';import LinkHeader from'http-link-header';import{createSelector}from'reselect';import*as BuildConfig from'soapbox/build-config';import{getAccessToken,getAppToken,isURL,parseBaseURL}from'soapbox/utils/auth';/**
  Parse Link headers, mostly for pagination.
  @see {@link https://www.npmjs.com/package/http-link-header}
  @param {object} response - Axios response object
  @returns {object} Link object
  */export var getLinks=function getLinks(response){var _response$headers;return new LinkHeader((_response$headers=response.headers)===null||_response$headers===void 0?void 0:_response$headers.link);};export var getNextLink=function getNextLink(response){var _getLinks$refs$find;return(_getLinks$refs$find=getLinks(response).refs.find(function(link){return link.rel==='next';}))===null||_getLinks$refs$find===void 0?void 0:_getLinks$refs$find.uri;};export var getPrevLink=function getPrevLink(response){var _getLinks$refs$find2;return(_getLinks$refs$find2=getLinks(response).refs.find(function(link){return link.rel==='prev';}))===null||_getLinks$refs$find2===void 0?void 0:_getLinks$refs$find2.uri;};var getToken=function getToken(state,authType){return authType==='app'?getAppToken(state):getAccessToken(state);};var maybeParseJSON=function maybeParseJSON(data){try{return JSON.parse(data);}catch(Exception){return data;}};var getAuthBaseURL=createSelector([function(state,me){return state.accounts.getIn([me,'url']);},function(state,_me){return state.auth.me;}],function(accountUrl,authUserUrl){var baseURL=parseBaseURL(accountUrl)||parseBaseURL(authUserUrl);return baseURL!==window.location.origin?baseURL:'';});/**
  * Base client for HTTP requests.
  * @param {string} accessToken
  * @param {string} baseURL
  * @returns {object} Axios instance
  */export var baseClient=function baseClient(accessToken){var baseURL=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'';return axios.create({// When BACKEND_URL is set, always use it.
baseURL:isURL(BuildConfig.BACKEND_URL)?BuildConfig.BACKEND_URL:baseURL,headers:Object.assign(accessToken?{'Authorization':"Bearer ".concat(accessToken)}:{}),transformResponse:[maybeParseJSON]});};/**
  * Dumb client for grabbing static files.
  * It uses FE_SUBDIRECTORY and parses JSON if possible.
  * No authorization is needed.
  */export var staticClient=axios.create({baseURL:BuildConfig.FE_SUBDIRECTORY,transformResponse:[maybeParseJSON]});/**
  * Stateful API client.
  * Uses credentials from the Redux store if available.
  * @param {function} getState - Must return the Redux state
  * @param {string} authType - Either 'user' or 'app'
  * @returns {object} Axios instance
  */export default(function(getState){var authType=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'user';var state=getState();var accessToken=getToken(state,authType);var me=state.me;var baseURL=me?getAuthBaseURL(state,me):'';return baseClient(accessToken,baseURL);});// The Jest mock exports these, so they're needed for TypeScript.
export var __stub=function __stub(_func){return 0;};export var __clear=function __clear(){return[];};