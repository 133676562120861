import"core-js/modules/es.string.repeat.js";import"core-js/modules/es.regexp.exec.js";import"core-js/modules/es.string.replace.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.promise.js";import"core-js/modules/es.regexp.to-string.js";import"core-js/modules/es.array.iterator.js";import"core-js/modules/es.typed-array.uint8-array.js";import"core-js/modules/es.typed-array.at.js";import"core-js/modules/es.typed-array.copy-within.js";import"core-js/modules/es.typed-array.every.js";import"core-js/modules/es.typed-array.fill.js";import"core-js/modules/es.typed-array.filter.js";import"core-js/modules/es.typed-array.find.js";import"core-js/modules/es.typed-array.find-index.js";import"core-js/modules/es.typed-array.find-last.js";import"core-js/modules/es.typed-array.find-last-index.js";import"core-js/modules/es.typed-array.for-each.js";import"core-js/modules/es.typed-array.includes.js";import"core-js/modules/es.typed-array.index-of.js";import"core-js/modules/es.typed-array.iterator.js";import"core-js/modules/es.typed-array.join.js";import"core-js/modules/es.typed-array.last-index-of.js";import"core-js/modules/es.typed-array.map.js";import"core-js/modules/es.typed-array.reduce.js";import"core-js/modules/es.typed-array.reduce-right.js";import"core-js/modules/es.typed-array.reverse.js";import"core-js/modules/es.typed-array.set.js";import"core-js/modules/es.typed-array.slice.js";import"core-js/modules/es.typed-array.some.js";import"core-js/modules/es.typed-array.sort.js";import"core-js/modules/es.typed-array.to-locale-string.js";import"core-js/modules/es.typed-array.to-string.js";import{createPushSubscription,updatePushSubscription}from'soapbox/actions/push-subscriptions';import{pushNotificationsSetting}from'soapbox/settings';import{getVapidKey}from'soapbox/utils/auth';import{decode as decodeBase64}from'soapbox/utils/base64';import{setBrowserSupport,setSubscription,clearSubscription}from'./setter';// Taken from https://www.npmjs.com/package/web-push
var urlBase64ToUint8Array=function urlBase64ToUint8Array(base64String){var padding='='.repeat((4-base64String.length%4)%4);var base64=(base64String+padding).replace(/-/g,'+').replace(/_/g,'/');return decodeBase64(base64);};var getRegistration=function getRegistration(){if(navigator.serviceWorker){return navigator.serviceWorker.ready;}else{throw'Your browser does not support Service Workers.';}};var getPushSubscription=function getPushSubscription(registration){return registration.pushManager.getSubscription().then(function(subscription){return{registration:registration,subscription:subscription};});};var subscribe=function subscribe(registration,getState){return registration.pushManager.subscribe({userVisibleOnly:true,applicationServerKey:urlBase64ToUint8Array(getVapidKey(getState()))});};var unsubscribe=function unsubscribe(_ref){var registration=_ref.registration,subscription=_ref.subscription;return subscription?subscription.unsubscribe().then(function(){return registration;}):new Promise(function(r){return r(registration);});};var sendSubscriptionToBackend=function sendSubscriptionToBackend(subscription,me){return function(dispatch,getState){var alerts=getState().push_notifications.alerts.toJS();var params={subscription:subscription,data:{alerts:alerts}};if(me){var data=pushNotificationsSetting.get(me);if(data){params.data=data;}}return dispatch(createPushSubscription(params));};};// Last one checks for payload support: https://web-push-book.gauntface.com/chapter-06/01-non-standards-browsers/#no-payload
// eslint-disable-next-line compat/compat
var supportsPushNotifications='serviceWorker'in navigator&&'PushManager'in window&&'getKey'in PushSubscription.prototype;var register=function register(){return function(dispatch,getState){var me=getState().me;var vapidKey=getVapidKey(getState());dispatch(setBrowserSupport(supportsPushNotifications));if(!supportsPushNotifications){console.warn('Your browser does not support Web Push Notifications.');return;}if(!vapidKey){console.error('The VAPID public key is not set. You will not be able to receive Web Push Notifications.');return;}getRegistration().then(getPushSubscription)// @ts-ignore
.then(function(_ref2){var registration=_ref2.registration,subscription=_ref2.subscription;if(subscription!==null){var _getState$push_notifi;// We have a subscription, check if it is still valid
var currentServerKey=new Uint8Array(subscription.options.applicationServerKey).toString();var subscriptionServerKey=urlBase64ToUint8Array(vapidKey).toString();var serverEndpoint=(_getState$push_notifi=getState().push_notifications.subscription)===null||_getState$push_notifi===void 0?void 0:_getState$push_notifi.endpoint;// If the VAPID public key did not change and the endpoint corresponds
// to the endpoint saved in the backend, the subscription is valid
if(subscriptionServerKey===currentServerKey&&subscription.endpoint===serverEndpoint){return{subscription:subscription};}else{// Something went wrong, try to subscribe again
return unsubscribe({registration:registration,subscription:subscription}).then(function(registration){return subscribe(registration,getState);}).then(function(subscription){return dispatch(sendSubscriptionToBackend(subscription,me));});}}// No subscription, try to subscribe
return subscribe(registration,getState).then(function(subscription){return dispatch(sendSubscriptionToBackend(subscription,me));});}).then(function(_ref3){var subscription=_ref3.subscription;// If we got a PushSubscription (and not a subscription object from the backend)
// it means that the backend subscription is valid (and was set during hydration)
if(!(subscription instanceof PushSubscription)){dispatch(setSubscription(subscription));if(me){pushNotificationsSetting.set(me,{alerts:subscription.alerts});}}}).catch(function(error){if(error.code===20&&error.name==='AbortError'){console.warn('Your browser supports Web Push Notifications, but does not seem to implement the VAPID protocol.');}else if(error.code===5&&error.name==='InvalidCharacterError'){console.error('The VAPID public key seems to be invalid:',vapidKey);}// Clear alerts and hide UI settings
dispatch(clearSubscription());if(me){pushNotificationsSetting.remove(me);}return getRegistration().then(getPushSubscription).then(unsubscribe);}).catch(console.warn);};};var saveSettings=function saveSettings(){return function(dispatch,getState){var state=getState().push_notifications;var alerts=state.alerts;var data={alerts:alerts};var me=getState().me;return dispatch(updatePushSubscription({data:data})).then(function(){if(me){pushNotificationsSetting.set(me,data);}}).catch(console.warn);};};export{register,saveSettings};